import { graphql } from "gatsby"
import React from "react"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import './ansicht.sass'
import ImageGallery from "../../components/image-gallery/image-gallery";
import {toPath} from "../../util/toPath";

export default function Template({ data }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { html, frontmatter } = markdownRemark

    return (
    <Layout>
        <SEO title="Aktuelles"/>
        <div className={'singleNewsPage container section bottom'}>

            <h1 className={'title'}>AKTUELLES</h1>
            <h4 className={'subtitle'}>{ frontmatter.title }</h4>
            <div className="second-container grid post">
                <div className="col-7sd col-6 col-12td col-12md col-12smd">

                    <div className="text-container left">
                        <h5 className={'title'}>{ frontmatter.subtitle }</h5>
                        <div className={'text-body'} dangerouslySetInnerHTML={{__html: html}}/>
                    </div>
                </div>
                <div className="col-5sd col-6 col-12td col-12md col-12smd padding">
                    <img className={'titleImage'} src={ toPath(frontmatter.thumbnail) } alt=""/>
                </div>
            </div>
            <div className="second-container grid">
                <div className="col-12">
                    <div className="postInfo">
                        <p className="date">{ frontmatter.date }</p>
                        <p className="authorName"></p>
                    </div>
                </div>

                <div className="col-12 left">

            <a href="/aktuelles" className={'secondary-button'}>Alle Mitteilungen</a>
                </div>
            </div>
        </div>

        <ImageGallery images={frontmatter.galleryImages}/>
    </Layout>
)};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        thumbnail
        date(formatString: "DD.MM.YYYY")
        galleryImages
      }
    }
  }
`;
