import React from "react";
import PropTypes from "prop-types"

import "./image-gallery.sass"
import HammerComponent from "react-hammerjs";
import {toPath} from "../../util/toPath";

class ImageGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {lightBoxActive: false, lightBoxImageIndex: 0, images: props.images || []}
    }

    listener = (ev) => this.onDown(ev);

    openLightBox(index) {
        if (!this.state.images || this.state.images.length < 1) {
            return;
        }
        const i = (this.state.images.length + index) % this.state.images.length;
        this.setState({lightBoxActive: true, lightBoxImageIndex: i});
    }

    closeLightBox() {
        this.setState({lightBoxActive: false});
    }

    nextImage() {
        this.openLightBox(this.state.lightBoxImageIndex + 1);
    }

    prevImage() {
        this.openLightBox(this.state.lightBoxImageIndex - 1);
    }

    handleSwipe(ev) {
        const thereshold = 100;
        if (ev.deltaX >= thereshold) {
            this.prevImage();
        } else if (ev.deltaX <= -thereshold) {
            this.nextImage();
        }
    }

    onDown(ev) {
        if (ev.key === "ArrowRight") {
            this.nextImage();
        } else if (ev.key === "ArrowLeft") {
            this.prevImage();
        } else if (ev.key === "Escape") {
            this.closeLightBox();
        } else {
            return;
        }
        ev.preventDefault();
    }


    render() {
        const images = this.state.images;
        return (
            <>
                <div className={'imageGallery grid'}>
                    {
                        (images) ? images.map((image, i) => (
                            <span key={i} className='singleImage' onClick={() => this.openLightBox(i)}><img src={toPath("/_prev", image)}
                                                                                                            alt=""/></span>)) : ""
                    }
                </div>
                {
                    (this.state.lightBoxActive) ?
                        (<HammerComponent options={{direction: 'DIRECTION_HORIZONTAL'}} onSwipe={(ev) => this.handleSwipe(ev)}>
                                <div className="lightbox">
                                    <div className="bg" onClick={() => this.closeLightBox()}></div>
                                    <img src={toPath(images[this.state.lightBoxImageIndex])} alt=""/>
                                    <div className="arrow left" onClick={() => this.prevImage()}></div>
                                    <div className="arrow right" onClick={() => this.nextImage()}></div>
                                </div>
                            </HammerComponent>
                        ) : ""
                }
            </>
        )
    }

    componentDidMount() {
        window.addEventListener("keydown", this.listener)
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.listener)
    }

};

ImageGallery.propTypes = {
    children: PropTypes.node,
};

export default ImageGallery
